import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import publisher from './modules/publisher'
import content from './modules/content'

Vue.use(Vuex)
export default new Vuex.Store({
    modules : {
        user,
        publisher,
        content
    }
})