import axios from 'axios';
import * as header from '@/helpers/header'
import {format,fromUnixTime} from 'date-fns'
const state = {
    lookLoading : false,
    lookResult : []
}

const getters = {
    getLookLoading : (state) => state.lookLoading,
    getLookResults : (state) => state.lookResult
}

const actions = {
    searchByUnique({commit, rootState}, id){
        commit('SET_LOOK_FOR_LOADING', true)
        let r = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
        axios.get(process.env.VUE_APP_GET_RESOURCE_BY_UNIQUE_ID + id + '?r=' + r, header.createHeader(rootState.user.token))
            .then(res => {
                let results = [];
                let data = {}

                if(id.charAt(0) === 'A'){

                    let publishedDateFormat = format(new Date(res.data.publishedDate), 'yyyy/MM/dd');
                    let createdDateFormat = format(new Date(res.data.createdDate), 'yyyy/MM/dd');

                    data = {
                        type : "article",
                        id: res.data.id,
                        uniqueID: res.data.uniqueID,
                        url: res.data.canonicalURL,
                        title: res.data.title,
                        author: res.data.author,
                        description: res.data.description,
                        channelImageURL: res.data.channelImageURL,
                        language: res.data.language,
                        publisher: res.data.publisher.publisherName,
                        publishedDate: publishedDateFormat,
                        createdDate: createdDateFormat,
                        media: res.data.mediaArray,
                        viewCount: res.data.viewCount,
                        reactionCount: res.data.reactionCount,
                        commentCount: res.data.commentCount,
                        channelID: res.data.channelID,
                        deleted : res.data.deleted,
                        comments_disabled : res.data.comments_disabled,
                        canHide: res.data.canHide
                    }
                }else if(id.charAt(0) === 'P'){
                    let publishedDateFormat = format(fromUnixTime(res.data.published_date), 'yyyy/MM/dd');

                    data = {
                        type : "podcast",
                        id: res.data.id,
                        uniqueID: res.data.unique_id,
                        url: res.data.url,
                        title: res.data.title,
                        author: res.data.author,
                        description: res.data.description,
                        show_name : res.data.show_name,
                        channelImageURL: res.data.channel.logo_url,
                        pod_url : res.data.pod_url,
                        language: res.data.channel.language,
                        publisher: res.data.channel.name,
                        publishedDate: publishedDateFormat,
                        // createdDate: createdDateFormat,
                        media: res.data.images[0].url,
                        viewCount: res.data.viewsCount.toString(),
                        reactionCount: res.data.reactionsCount.toString(),
                        commentCount: res.data.commentsCount.toString(),
                        channelID: res.data.channel.channel_id,
                        deleted : res.data.deleted,
                        comments_disabled : res.data.comments_disabled,
                        canHide: res.data.canHide
                    }
                }else{
                    let publishedDateFormat = format(fromUnixTime(res.data.published_date), 'yyyy/MM/dd');

                    data = {
                        type : "video",
                        id: res.data.id,
                        uniqueID: res.data.unique_id,
                        url: res.data.page_url,
                        title: res.data.title,
                        author: res.data.author,
                        description: res.data.description,
                        channelImageURL: res.data.channel.logo_url,
                        language: res.data.channel.language,
                        publisher: res.data.channel.name,
                        publishedDate: publishedDateFormat,
                        // createdDate: createdDateFormat,
                        media: res.data.files[0].url,
                        viewCount: res.data.viewsCount.toString(),
                        reactionCount: res.data.reactionsCount.toString(),
                        commentCount: res.data.commentsCount.toString(),
                        channelID: res.data.channel.id,
                        deleted : res.data.deleted,
                        comments_disabled : res.data.comments_disabled,
                        canHide: res.data.canHide
                    }
                }
                results.unshift(data)
                commit('SET_LOOK_FOR_RESULT', results)
                commit('SET_LOOK_FOR_LOADING', false)
            })
            .catch(err => {
                console.log(err)
                commit('SET_LOOK_FOR_LOADING', false)
            })
    },
    searchArticle({commit, rootState}, search) {
        if(!search.skip){
            commit('SET_LOOK_FOR_LOADING', true)
        }
        let r = Math.floor(Math.random() * (1000 - 1 + 1)) + 1;
        let url = process.env.VUE_APP_GET_SEARCH + '?q=' + search.keyword + '&type=' + search.type + '&r=' + r
        if(search.page){
            url = process.env.VUE_APP_GET_SEARCH + '?q=' + search.keyword + '&page=' + search.page  + '&type=' + search.type + '&r=' + r
        }
        return new Promise((resolve, reject) => {
            axios.get(url, header.createHeader(rootState.user.token))
                .then(res => {
                    let objContent;
                    if(search.type == 'articles') {
                        objContent = res.data.articles;
                    } else if(search.type == 'videos') {
                        objContent = res.data.videos;
                    } else if(search.type == 'podcasts') {
                        objContent = res.data.podcasts;
                    }

                    let aContent = objContent.map(function (content) {

                        let objPublisher = {};
                        let publishedDate = '';
                        let uniqueID = '';
                        if(search.type == 'articles') {
                            objPublisher = content.publisher;
                            publishedDate = new Date(content.publishedDate);
                            uniqueID = content.uniqueID;
                        } else if(search.type == 'videos') {
                            objPublisher.publisherName = content.channel.name;
                            publishedDate = new Date(content.published_date * 1000);
                            uniqueID = content.unique_id;
                        } else if(search.type == 'podcasts') {
                            objPublisher.publisherName = content.channel.name;
                            publishedDate = new Date(content.published_date * 1000);
                            uniqueID = content.unique_id;
                        }

                        let data = {
                            id: content.id,
                            uniqueID: uniqueID,
                            url: content.canonicalURL,
                            title: content.title,
                            author: content.author,
                            description: content.description,
                            channelImageURL: content.channelImageURL,
                            language: content.language,
                            publisher: objPublisher.publisherName,
                            publishedDate: format(publishedDate, 'yyyy/MM/dd'),
                            media: content.mediaArray,
                            viewCount: content.viewCount,
                            reactionCount: content.reactionCount,
                            commentCount: content.commentCount,
                            channelID: content.channelID,
                            deleted : content.deleted,
                            canHide: content.canHide
                        }
                        return data;
                    });
                    if(!search.skip){
                        commit('SET_LOOK_FOR_RESULT', aContent)
                        commit('SET_LOOK_FOR_LOADING', false)
                    }
                    resolve(aContent)
                })
                .catch(error => {
                    console.log(error)
                    commit('SET_LOOK_FOR_LOADING', false)
                    reject(error)
                })

        })
    },
    hideData({commit, rootState}, article) {
        commit('SET_LOOK_FOR_LOADING', true)
        return new Promise((resolve, reject) => {
            axios.put(process.env.VUE_APP_HIDE_ARTICLE + article.unique_id, {}, header.createHeader(rootState.user.token))
                .then(res => {
                    let pass = {uniqueID : article.unique_id, status : '1'}
                    commit('SET_UPDATE_HIDE_STATUS', pass)
                    commit('SET_LOOK_FOR_LOADING', false)
                    resolve(res)
                })
                .catch(error => {
                    commit('SET_LOOK_FOR_LOADING', false)
                    reject(error)
                })
        })
    },
    showData({commit, rootState}, article){
        commit('SET_LOOK_FOR_LOADING', true)
        return new Promise( (resolve, reject) => {
            axios.put(process.env.VUE_APP_SHOW_ARTICLE + article.unique_id, article, header.createHeader(rootState.user.token))
                .then(res => {
                    let pass = {uniqueID : article.unique_id, status : '0'}
                    commit('SET_UPDATE_HIDE_STATUS', pass)
                    commit('SET_LOOK_FOR_LOADING', false)
                    resolve(res)
                })
                .catch(error => {
                    commit('SET_LOOK_FOR_LOADING', false)
                    reject(error)
                })
        })
    }
}

const mutations = {
    SET_LOOK_FOR_LOADING : (state, val) => state.lookLoading = val,
    SET_LOOK_FOR_RESULT : (state, res) => state.lookResult = res,
    SET_UPDATE_HIDE_STATUS : (state, newVal) => {
        let idx = state.lookResult.findIndex(res => res.uniqueID === newVal.uniqueID )
        let find = state.lookResult.find(res => res.uniqueID === newVal.uniqueID)
        if(idx !== -1 && find){
            find.deleted = newVal.status
            state.lookResult.splice(idx, 1, find)
        }
    }
}

// function convertDateTime(datetime){
//     let convertDate = new Date(datetime * 1000);
//     return convertDate.getFullYear() + '/' + convertDate.getMonth() + '/' + convertDate.getDate();
// }

export default {
    state,
    actions,
    getters,
    mutations
}