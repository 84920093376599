import axios from 'axios'
import * as header from '@/helpers/header'

// const config = {
//     headers: {
//         hash: process.env.VUE_APP_ADMIN_TOKEN,
//     },
// }
const state = {
    all_time : [],
    all_time_videos : [],
    publishers : [],
    tracking : [],
    all_time_loading : false,
    report_loading : false,
    rev : [],
    rev_loading: false,
    rev_summary: {
        'total_imp': 0,
        'total_ecpm': 0,
        "total_est": 0,
        "total_bonus": false,
        "total_est_after_bonus": false,
    }
}

const getters = {
    getAllTime : (state) => state.all_time,
    getAllTimeVideos : (state) => state.all_time_videos,
    getPublishers : (state) => state.publishers,
    getTracking : (state) => state.tracking,
    getAllTimeLoading : (state) => state.all_time_loading,
    getReportLoading : (state) => state.report_loading,
    getRevenue: (state) => state.rev,
    getRevLoading: (state) => state.rev_loading,
    getRevSummary: (state) => state.rev_summary
}

const actions = {
    listPublishers({commit, rootState}) {
        commit('SET_TRACKING', [])
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_LIST_PUBLISHERS, header.createHeader(rootState.user.token, process.env.VUE_APP_ADMIN_TOKEN))
                .then(res => {
                    let data = []
                    res.data.map(item => {
                        let base = {
                            value: item.publisher_id,
                            label: item.name
                        }
                        if (!data.find(data => data.value === base.value)) {
                            data.push(base)
                        }
                    })
                    let partner = {
                        value : 99999,
                        label : 'All Partners'
                    }
                    data.unshift(partner)
                    data.sort((a, b) => a.label.localeCompare(b.label))
                    resolve(data)
                    commit('SET_PUBLISHERS', data)
                })
                .catch(err => {
                    alert("Something went wrong, check log")
                    console.log(err)
                    reject(err)
                })
        })

    },
    trackAllTimeReport({commit, rootState}, publisher) {
        commit('SET_ALL_TIME_LOADING', true)
        commit('SET_TRACKING', [])
        let r = Math.floor(Math.random() * (1000 - 1 + 1 ) ) + 1;
        let url = process.env.VUE_APP_PUBLISHER_REVENUE+publisher.publisher_ids+'/dashboard/articles/cumulative/v2?dateFrom='+publisher.dateFrom+'&dateTo='+publisher.dateTo+'&r='+r
        axios.get(url, header.createHeader(rootState.user.token, process.env.VUE_APP_ADMIN_TOKEN))
            .then(res => {
                commit('SET_ALL_TIME_DATA', res.data.article)
                commit('SET_ALL_TIME_DATA_VIDEOS', res.data.video)
                commit('SET_ALL_TIME_LOADING', false)
            })
            .catch(err => {
                alert("Something went wrong, check logs")
                console.log(err)
                commit('SET_ALL_TIME_LOADING', false)
            })
    },
    trackTimeSeriesReport({commit, rootState}, publisher) {
        commit('SET_REPORT_LOADING', true)
        commit('SET_TRACKING', [])
        let r = Math.floor(Math.random() * (1000 - 1 + 1 ) ) + 1;
        let url = process.env.VUE_APP_PUBLISHER_REVENUE+publisher.publisher_ids+'/dashboard/articles/timeseries/v2?dateFrom='+publisher.dateFrom+'&dateTo='+publisher.dateTo+'&r='+r
        axios.get(url, header.createHeader(rootState.user.token, process.env.VUE_APP_ADMIN_TOKEN))
            .then(res => {
                commit('SET_TRACKING', res.data)
                commit('SET_REPORT_LOADING', false)
            })
            .catch(err => {
                commit('SET_REPORT_LOADING', false)
                alert("Something went wrong, check logs")
                console.log(err)
            })
    },
    getEstimateRevenue({commit, rootState}, publisher) {
        commit('SET_REV_LOADING', true)
        commit('SET_REV', [])
        let r = Math.floor(Math.random() * (1000 - 1 + 1 ) ) + 1;
        let id = publisher.publisher_ids[0]
        let url = process.env.VUE_APP_PUBLISHER_REVENUE+id+'/dashboard/revenue?startDate='+publisher.dateFrom+'&endDate='+publisher.dateTo+'&r='+r
        axios.get(url, header.createHeader(rootState.user.token, process.env.VUE_APP_ADMIN_TOKEN))
            .then(res => {
                commit('SET_REV', res.data.data)
                commit('SET_REV_SUMMARY', res.data.summary)
                console.log(res.data.summary)
                commit('SET_REV_LOADING', false)
            })
            .catch(err => {
                commit('SET_REV_LOADING', false)
                alert("Something went wrong, check logs")
                console.log(err)
            })
    },
}

const mutations = {
    SET_ALL_TIME_DATA : (state, data) => state.all_time = data,
    SET_ALL_TIME_DATA_VIDEOS : (state, data) => state.all_time_videos = data,
    SET_PUBLISHER : (state, publishers) => state.publisher = publishers,
    SET_TRACKING : (state, report) => state.tracking = report,
    SET_ALL_TIME_LOADING : (state, val) => state.all_time_loading = val,
    SET_REPORT_LOADING : (state, val) => state.report_loading = val,
    SET_PUBLISHERS: (state, pubs) => state.publishers = pubs,
    SET_REV: (state, data) => state.rev = data,
    SET_REV_LOADING: (state, val) => state.rev_loading = val,
    SET_REV_SUMMARY: (state, val) => state.rev_summary = val

}

export default {
    state,
    actions,
    getters,
    mutations
}