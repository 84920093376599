import axios from 'axios'

const state =  {
    token : localStorage.getItem('token') || null,
    user : JSON.parse(localStorage.getItem('user')) || {},
    token_expire_at : localStorage.getItem('expiry') || null,
    publishers : JSON.parse(localStorage.getItem('publishers')) || [],
    access: [],
    isAdmin: localStorage.getItem('isAdmin') === "true" || false,
}

const getters = {
    isLoggedIn: (state) => {
        return state.token !== null && state.user !== {} && state.token_expire_at !== null
    },
    isTokenValid : (state) => {
        let now = new Date().getTime()
        let expiry = new Date(state.token_expire_at).getTime()
        return expiry > now
    },
    getUser : (state) => state.user,
    getToken : (state) => state.token,
    getUserPublishers : (state) => state.publishers,
    getTokenExpiry : (state) => state.token_expire_at,
    getAccess: (state) => state.access,
    getIsAdmin: (state) => state.isAdmin
}

const actions = {
    login(context, user){
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_LOGIN, user)
                .then(res => {
                    let data = res.data.publishers
                    data.sort((a, b) => a.name.localeCompare(b.name))
                    context.commit('SET_ADMIN', res.data.user.email.endsWith("@newswav.com"))
                    context.commit('SET_USER', res.data.user)
                    context.commit('SET_TOKEN', res.data.access_token)
                    context.commit('SET_USER_PUBLISHER', data)
                    context.commit('SET_EXPIRY_DATE', res.data.expires_in)
                    localStorage.setItem('token', res.data.access_token)
                    localStorage.setItem('user', JSON.stringify(res.data.user))
                    localStorage.setItem('publishers', JSON.stringify(data))
                    localStorage.setItem('expiry', res.data.expires_in)
                    localStorage.setItem('isAdmin', res.data.user.email.endsWith("@newswav.com"))
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
                    resolve(res)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    },
    logout(context) {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_LOGOUT, {},{headers : {Authorization : 'Bearer '+context.state.token}})
                .then(res => {
                    context.commit('SET_USER', {})
                    context.commit('SET_TOKEN', null)
                    context.commit('SET_USER_PUBLISHER', [])
                    context.commit('SET_EXPIRY_DATE', null)
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('expiry')
                    localStorage.removeItem('publishers')
                    localStorage.removeItem('isAdmin')
                    delete axios.defaults.headers.common['Authorization']
                    resolve(res)
                })
                .catch(err => {
                    context.commit('SET_USER', {})
                    context.commit('SET_TOKEN', null)
                    context.commit('SET_USER_PUBLISHER', [])
                    context.commit('SET_EXPIRY_DATE', null)
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('expiry')
                    localStorage.removeItem('publishers')
                    localStorage.removeItem('isAdmin')
                    delete axios.defaults.headers.common['Authorization']
                    reject(err)
                })
        })
    },
    sendResetEmail(context, email){
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_SEND_RESET_LINK, email)
                .then( res =>{
                    resolve(res.data.message)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    },
    changePassword(context, user){
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_CHANGE_PASSWORD, user, {headers : {Authorization : 'Bearer '+context.state.token}})
                .then(res => {
                    resolve(res.data.message)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    },
    resetPassword(context, user){
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_RESET_PASSWORD, user)
                .then(res => {
                    resolve(res.data.message)
                })
                .catch(err => {
                    reject(err.response.data.message)
                })
        })
    },
    getAccess(context){
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_PUBLISHER_ACCESS, {headers : {Authorization : 'Bearer '+ context.state.token}})
                .then(res => {
                    context.commit('SET_ACCESS', res.data)
                    resolve(res)
                })
                .catch(err => {
                    console.log(err)
                    reject(err.response.data.message)
                })
        })
    }
}

const mutations = {
    SET_USER : (state, user) => state.user = user,
    SET_TOKEN  : (state, token) => state.token = token,
    SET_EXPIRY_DATE : (state, date) => state.token_expire_at = date,
    SET_USER_PUBLISHER : (state, list) => state.publishers = list,
    SET_ACCESS : (state, list) => state.access = list,
    SET_ADMIN : (state, isAdmin) => state.isAdmin = isAdmin
}




export default {
    state,
    actions,
    getters,
    mutations
}