import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import '@fortawesome/fontawesome-free/css/all.css'
Vue.use(Vuetify)

const opts = {
    colors,
    iconfont: 'fa'
}

export default new Vuetify(opts)