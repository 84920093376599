export function createHeader(utoken, nwtoken = null, type = "user"){
    if (nwtoken){
        return {
            headers :
                {
                    Authorization : 'Bearer '+ utoken,
                    token: nwtoken,
                    hash: nwtoken,
                    dashboard: 1,
                    'request-type' : type,
                    languages: 'en,zh,ms',
                }
        }
    }else{
        return {
            headers :
                {
                    Authorization : 'Bearer '+ utoken,
                    dashboard: 1,
                    'request-type' : type,
                    languages: 'en,zh,ms',
                }
        }
    }
}