import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
import axios from "axios";

const GenerateReport = () => import('@/components/Publisher/GenerateReport')

const Login = () => import('@/components/User/Login')
const ForgotPassword = () => import('@/components/User/ForgotPassword')
const SetPassword = () =>  import('@/components/User/SetPassword')
const Profile = () => import('@/components/User/Profile')

const MainLayout = () => import('@/container/MainLayout')
const HomeDashboard = () => import('@/components/HomeDashboard')
const Search = () => import('@/components/Content/Search')
const MonetizationDashboard = () => import('@/components/MonetizationDashboard')

Vue.use(Router)
export const router = new Router({
    mode : 'history',
    linkActiveClass: 'active',
    routes : configRoutes()
})

function configRoutes(){

    return [
        {
          path : '/login',
          component : Login
        },
        {
            path : '/forgot-password',
            component : ForgotPassword
        },
        {
            path : '/reset-password/:token/:email',
            component : SetPassword
        },
        {
            path: '/',
            redirect : '/dashboard',
            name : 'home',
            meta : {
                requiresAuth : true
            },
            component: MainLayout,
            children : [
                {
                    path : 'dashboard',
                    name : 'Dashboard',
                    meta : {
                        requiresAuth : true
                    },
                    component : HomeDashboard
                },
                {
                    path : 'profile',
                    name : 'Partner Profile',
                    meta : {
                        requiresAuth : true
                    },
                    component : Profile
                },
                {
                    path : '/search',
                    name : 'search',
                    meta: {
                        requiresAuth: true,
                        access:['search'],
                    },
                    component : Search
                },
                {
                    path : 'monetization',
                    name : 'Monetization',
                    meta : {
                        requiresAuth : true,
                        access:['monetization'],
                    },
                    component : MonetizationDashboard
                }
            ]
        },
        {
            path : '/generate-report/:publisher_id/:dateFrom/:dateTo',
            meta : {
                requiresAuth : true
            },
            component : GenerateReport
        }
    ]

}

router.beforeEach(async (to,from, next) => {
    let auth = store.getters.isLoggedIn
    let valid = store.getters.isTokenValid
    let access = store.getters.getAccess
    let allow = false
    if (to.path === '/login'){
        if(auth && valid) {
            next({path:'/dashboard'})
        }
    }

    if(to.matched.some(record => record.meta.requiresAuth)){
        if(auth && valid){
            if (to.meta.access){
                console.log(to.meta.access)
                if (access.length == 0){
                    await store.dispatch('getAccess')
                    access = store.getters.getAccess
                }
                for(var i = 0; i < access.length; i++){
                    if(to.meta.access.indexOf(access[i]) != -1){
                        allow = true
                    }
                }
                if (allow == true){
                    next()
                }else{
                    next('/dashboard')
                }
            }else{
                next()
            }
        }else {
            store.commit('SET_USER', {})
            store.commit('SET_TOKEN', null)
            store.commit('SET_EXPIRY_DATE', null)
            store.commit('SET_USER_PUBLISHER', [])
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('expiry')
            localStorage.removeItem('publishers')
            delete axios.defaults.headers.common['Authorization']
            next({path: '/login', returnUrl: {query: to.fullPath}})
        }
    }else{
        next()
    }
})